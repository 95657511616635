import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { nl } from 'date-fns/locale';
import {
  DataProductPlanSubscription,
  useGetSubscriptionsQuery,
} from '../../generated/gql/types';
import { WeCityOrganizationIdHeader } from '../../Global';
import ProductSupportCard from '../library/ProductSupportCard';
import validateOdps, { ODPS } from '../../Helpers';
import DataProductCard from '../library/DataProductCard';
import PricingPlanCard from '../library/PricingPlanCard';
import SubscriptionTabGroup from '../library/SubscriptionTabGroup';
import SubscriptionUsageDonutChart from '../library/SubscriptionUsageDonutChart';

function MySubscription() {
  const params = useParams();
  const [product, setProduct] = useState<CatalogProductModel | undefined>(undefined);

  const { data, loading } = useGetSubscriptionsQuery({
    fetchPolicy: 'network-only',
    context: {
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });

  useEffect(() => {
    if (!loading && data?.dataMarket.subscriptions) {
      const entity = data?.dataMarket.subscriptions?.find((s) => s.id === params.subscriptionId);

      if (entity !== undefined) {
        setProduct({
          subscription: entity,
          odps: validateOdps(entity?.product.openDataProductSpecification),
        });
      }
    }
  }, [loading, data]);

  function renderSupport() {
    const subscription = data?.dataMarket.subscriptions?.find((sub) => sub.id === params.subscriptionId);

    if (subscription) {
      const odps = validateOdps(subscription.product.openDataProductSpecification);
      if (odps) {
        return <ProductSupportCard productName={odps.product.en.name ?? 'onbekend'} productId={subscription.product.id} planId={subscription.planId} />;
      }
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <div className="mx-auto relative max-w-screen-xl flex items-center justify-center w-full pt-4">
      {(!loading && product && product.odps !== undefined) ? (
        <div className="flex-col w-full justify-center">
          <div className="flex-col space-y-6 lg:flex lg:flex-row lg:space-x-6 lg:space-y-0">
            <DataProductCard isMini odps={product.odps} subscription={product.subscription} />
            {/* <ProductTabGroup odps={product.odps} planId={product.subscription.planId} /> */}
          </div>
          <div className="relative flex py-5 items-center">
            <div className="flex-grow border-t border-gray-300" />
            <span className="flex-shrink mx-4 text-gray-600 text-lg items-center font-semibold">Mijn Product</span>
            <div className="flex-grow border-t borde1r-gray-300" />
          </div>
          <div className="flex-col space-y-6 lg:flex lg:flex-row lg:space-x-6 lg:space-y-0">
            <SubscriptionTabGroup subscription={product.subscription} />
            <div className="space-y-6">
              <div className="bg-white rounded-lg p-6">
                <div className="flex text-xs items-center gap-x-1">
                  <div className="text-gray-500 text-xs">
                    Startdatum abonnement
                  </div>
                  <div className="text-gray-700 font-semibold">
                    {format(parseISO(product.subscription.createdAt), 'dd-MM-y', { locale: nl })}
                  </div>
                </div>
                <SubscriptionUsageDonutChart subscription={product.subscription} maxCalls={Number.parseInt(product.odps.product.pricingPlans.en.find((plan) => plan['x-wecity'].planId === product.subscription?.planId)?.maxTransactionQuantity.toString() ?? '100', 10)} />
              </div>
              {renderSupport()}
            </div>
          </div>

          <div className="relative flex py-5 items-center">
            <div className="flex-grow border-t border-gray-300" />
            <span className="flex-shrink mx-4 text-gray-600 text-lg items-center font-semibold">Mijn Abonnement</span>
            <div className="flex-grow border-t border-gray-300" />
          </div>
          <div className="flex-col space-y-6 lg:flex lg:flex-row lg:space-x-6 lg:space-y-0 pb-4">
            {product.odps.product.pricingPlans.en.find((plan) => plan['x-wecity'].planId === product.subscription?.planId) && (
              <PricingPlanCard
                productId={product.subscription.product.id}
                productName={product.odps.product.en.name}
                pricingPlan={product.odps.product.pricingPlans.en.find((plan) => plan['x-wecity'].planId === product.subscription?.planId)}
                isMini
              />
            )}
            <div className="pl-10 py-6 overflow-x-auto flex-col w-full bg-white rounded-lg">
              <h2 className="pb-4 w-full bg-white text-2xl font-bold">Abonnement informatie</h2>
              <p className="text-gray-500 text-base">Bekijk de gebruiksvoorwaarden voor dit product</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="columns-3 md:columns-2 lg:columns-3 gap-12 space-y-12 py-12 w-full">
          <div
            className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
          />
          <div
            className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
          />
          <div
            className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
          />
        </div>
      )}
    </div>
  );
}

type CatalogProductModel = {
  subscription: DataProductPlanSubscription
  odps: ODPS | undefined
};

export default MySubscription;
