import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import {
  Button, Checkbox, Label, Textarea, TextInput,
} from 'flowbite-react';
import Select, { SingleValue } from 'react-select';
import {
  CreateAdminProductCommand,
  HttpScheme,
  RoleOrganization,
  RoleUser, useAdminUpdateDataProductMutation,
  useGetAdminDataProductQuery,
  useGetMyOrganizationRolesQuery,
  useGetMyUserRolesQuery,
  useSetDataProductHttpSourceMutation,
} from '../../generated/gql/types';
import Loader from '../Loader';
import { WeCityOrganizationIdHeader } from '../../Global';
import DataProductCard from '../library/DataProductCard';
import PricingPlanCard from '../library/PricingPlanCard';
import { OdpsValidationResult, validateOdpsWithExceptions } from '../../Helpers';

function UpdateDataProduct() {
  const params = useParams();
  const { data: myRoles, loading: userRolesLoading } = useGetMyUserRolesQuery();
  const { data: organizationRoles, loading: organizationRolesLoading } = useGetMyOrganizationRolesQuery({
    fetchPolicy: 'network-only',
  });
  const { data: product, loading: productLoading } = useGetAdminDataProductQuery({
    variables: {
      id: params.productId ?? '',
    },
    context: {
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });

  const [updateDataProduct, {
    data: updateDataProductResponse,
    loading: updateDataProductLoading,
    error: updateDataProductError,
  }] = useAdminUpdateDataProductMutation({});

  const [setHttpSource, {
    data: setHttpSourceResponse,
    loading: setHttpSourceLoading,
    error: setHttpSourceError,
  }] = useSetDataProductHttpSourceMutation({});

  const {
    register,
    unregister,
    setValue,
    watch,
    handleSubmit,
  } = useForm<UpdateCommandWithOrgId>({
    defaultValues: {
      orgId: params.orgId,
    },
  });
  const odpsValueWatch = watch('command.openDataProductSpecification');

  function setOrganization(e: SingleValue<{ label: string, value: string }>) {
    setValue('orgId', e?.value ?? '');
  }

  function setScheme(e: SingleValue<{ label: string, value: HttpScheme }>) {
    setValue('scheme', e?.value ?? HttpScheme.Http);
  }

  const [odpsValidationResult, setOdpsValidationResult] = useState<OdpsValidationResult | undefined>(undefined);
  const onSubmit = handleSubmit((updateCommand) => {
    updateDataProduct({
      variables: {
        command: {
          id: product?.dataMarket.adminProduct.id!,
          dataType: product?.dataMarket.adminProduct.dataType!,
          active: updateCommand.command.active,
          openDataProductSpecification: updateCommand.command.openDataProductSpecification,
        },
      },
      context: {
        headers: {
          [WeCityOrganizationIdHeader]: updateCommand.orgId,
        },
      },
    }).then((response) => {
      if (response.data) {
        setHttpSource({
          variables: {
            command: {
              dataProductId: response.data?.dataMarket.adminUpdateProduct.id!,
              scheme: updateCommand.scheme,
              basePath: updateCommand.basePath,
              host: updateCommand.host,
              port: updateCommand.port,
              forwardedHeaders: [],
            },
          },
          context: {
            headers: {
              [WeCityOrganizationIdHeader]: updateCommand.orgId,
            },
          },
        });
      }
    });
  });

  useEffect(() => {
    if (product?.dataMarket?.adminProduct) {
      setScheme({ label: (product.dataMarket.adminProduct.httpSource?.scheme ?? HttpScheme.Http).toString(), value: product.dataMarket.adminProduct.httpSource?.scheme ?? HttpScheme.Http });
    }
  }, [product, productLoading]);

  useEffect(() => {
    setOdpsValidationResult(undefined);
    if (odpsValueWatch) {
      try {
        const odps = validateOdpsWithExceptions(odpsValueWatch);
        setOdpsValidationResult({ errorMessage: undefined, success: true, validOdps: odps });
      } catch (exception: any) {
        setOdpsValidationResult({ errorMessage: exception?.toString(), success: false, validOdps: undefined });
      }
    }
  }, [register, unregister, odpsValueWatch]);

  if (userRolesLoading || organizationRolesLoading) {
    return <Loader />;
  }

  function getOrganizationList() {
    return organizationRoles?.myOrganizationRoles.filter((r) => r.organizationRoles.includes(RoleOrganization.OrgDataMarketSubscriptionManager)).map((item) => (
      { label: `${item.organization.orgId} (${item.organizationRoles.join(', ')})`, value: item.organization.orgId }
    )) ?? [];
  }

  if (userRolesLoading || productLoading) {
    return <Loader />;
  }

  function renderSubmitButton() {
    if (updateDataProductLoading || setHttpSourceLoading) {
      return (
        <Button
          type="submit"
          className="opacity:50 pointer-events-none inline-flex items-center justify-self-center self-start rounded-lg p-2 w-80 text-center font-medium"
          disabled
        >
          loading...
        </Button>
      );
    }

    if (odpsValidationResult && odpsValidationResult.success) {
      return (
        <Button
          type="submit"
          className="inline-flex items-center justify-self-center self-start rounded-lg p-2 w-80 text-center font-medium"
        >
          Dataproduct updaten
        </Button>
      );
    }

    return (
      <Button
        type="submit"
        className="opacity:50 pointer-events-none inline-flex items-center justify-self-center self-start rounded-lg p-2 w-80 text-center font-medium"
        disabled
      >
        Dataproduct updaten
      </Button>
    );
  }

  if (myRoles?.myUserRoles.find((r) => r === RoleUser.UserGlobalAdmin)) {
    return (
      <div className="mx-auto relative max-w-screen-xl flex items-center justify-center w-full bg-gray-100 py-6">
        <div className="flex-col space-y-6 gap-6  w-full">
          <div className="text-lg flex gap-x-1">
            ODPS JSON valideren
            {' '}
            <Link
              to="https://open-data-product-initiative.github.io/open-data-product-spec-2.1/#open-data-product-specification-2-1"
              target="_blank"
            >
              <div className="underline">more info</div>
            </Link>
          </div>
          <form className="flex-col space-y-6 min-h-full" onSubmit={onSubmit}>
            <Textarea rows={10} className="w-full" {...register('command.openDataProductSpecification', { value: product?.dataMarket?.adminProduct?.openDataProductSpecification ?? '' })} />
            <div>
              {odpsValidationResult ? (
                <>
                  <div>
                    Odps Validation:
                    {' '}
                    {odpsValidationResult.success ? 'Success' : odpsValidationResult.errorMessage}
                  </div>
                  {odpsValidationResult.validOdps && (
                    <div className="space-y-6">
                      <DataProductCard odps={odpsValidationResult.validOdps} />
                      <div
                        className="columns-1 md:columns-2 lg:columns-3 gap-12 space-y-6 lg:w-full break-inside-avoid-column"
                      >
                        {odpsValidationResult.validOdps?.product?.pricingPlans?.en.map((pricingPlan: any) => (
                          <div key={`pricplan-${crypto.randomUUID()}`} className="flex lg:block justify-center">
                            <PricingPlanCard
                              pricingPlan={pricingPlan}
                              productName={odpsValidationResult.validOdps?.product?.en?.name ?? 'Plan'}
                              productId="00000000-0000-0000-0000-000000000000"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              ) : ('')}
            </div>
            <Select
              required
              placeholder="Select organization"
              isLoading={organizationRolesLoading}
              options={getOrganizationList()}
              defaultValue={getOrganizationList().find((org) => org.value === params.orgId)}
              className="w-full"
              onChange={(e) => setOrganization(e)}
            />
            <div className="flex gap-x-2 items-center">
              {product?.dataMarket?.adminProduct?.active ? (
                <Checkbox
                  checked
                  id="active"
                  {...register('command.active', { required: true, value: product?.dataMarket?.adminProduct?.active })}
                />
              ) : (
                <Checkbox
                  id="active"
                  {...register('command.active', { required: true, value: product?.dataMarket?.adminProduct?.active })}
                />
              )}

              <Label htmlFor="active" className="flex">
                Product active
              </Label>
            </div>
            <Select
              required
              placeholder="Scheme"
              options={[
                { label: HttpScheme.Http.toString(), value: HttpScheme.Http },
                { label: HttpScheme.Https.toString(), value: HttpScheme.Https },
              ]}
              defaultValue={{ label: (product?.dataMarket?.adminProduct?.httpSource?.scheme ?? HttpScheme.Http).toString(), value: product?.dataMarket?.adminProduct?.httpSource?.scheme ?? HttpScheme.Http }}
              className="w-full"
              onChange={(e) => setScheme(e)}
            />
            <TextInput
              required
              type="text"
              placeholder='Host i.e. "example.com" or "spotinfo-proxy"'
              {...register('host', { required: true, value: product?.dataMarket?.adminProduct?.httpSource?.host })}
            />
            <TextInput
              required
              type="number"
              placeholder='Port i.e. "8080"'
              {...register('port', { required: true, valueAsNumber: true, value: product?.dataMarket?.adminProduct?.httpSource?.port })}
            />
            <TextInput
              required
              type="text"
              placeholder='BasePath i.e. "/" or "/parkeerdruk_amersfoort"'
              {...register('basePath', { required: true, value: product?.dataMarket?.adminProduct?.httpSource?.basePath })}
            />
            {!updateDataProductResponse && !setHttpSourceResponse && (
              renderSubmitButton()
            )}
          </form>

          {updateDataProductResponse && (
            <div>
              Updated data product with id:
              {' '}
              {updateDataProductResponse.dataMarket.adminUpdateProduct.id}
              {' '}
              {process.env.REACT_APP_ENVIRONMENT === 'production' ? (
                <Link
                  className="underline text-wc-blue"
                  target="_blank"
                  to={`https://data-market.platform.wecity.nl/catalog/${updateDataProductResponse.dataMarket.adminUpdateProduct.id}`}
                >
                  Product
                  link when active
                </Link>
              ) : (
                <Link
                  className="underline text-wc-blue"
                  target="_blank"
                  to={`https://data-market.acc-platform.wecity.nl/catalog/${updateDataProductResponse.dataMarket.adminUpdateProduct.id}`}
                >
                  Product
                  link when active
                </Link>
              )}
            </div>
          )}

          {setHttpSourceResponse && (
            <div>
              Updated http source for data product:
              {' '}
              {setHttpSourceResponse.dataMarket.adminSetHttpSource.scheme.toLowerCase()}
              ://
              {setHttpSourceResponse.dataMarket.adminSetHttpSource.host}
              :
              {setHttpSourceResponse.dataMarket.adminSetHttpSource.port}
              {setHttpSourceResponse.dataMarket.adminSetHttpSource.basePath}
            </div>
          )}

          {updateDataProductError && (
            <div className="text-red-500 font-bold">
              Error occured when upating data product:
              {' '}
              {updateDataProductError.message}
            </div>
          )}

          {setHttpSourceError && (
            <div className="text-red-500 font-bold">
              Error occured when updating http source:
              {' '}
              {setHttpSourceError.message}
            </div>
          )}
        </div>
      </div>
    );
  }

  return <div>No access</div>;
}

type UpdateCommandWithOrgId = {
  command: CreateAdminProductCommand
  scheme: HttpScheme
  orgId: string
  host: string
  basePath: string
  port: number
};

export default UpdateDataProduct;
