import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'flowbite-react';
import { OfferingsLocalStorageKey, WeCityOrganizationIdHeader } from '../../../Global';
import {
  useGetMyProductQuery,
  useSubmitDataProductMutation,
} from '../../../generated/gql/types';
import { OfferWizardCongratulationsRoute } from '../../Routes';
import DataProductCard from '../../library/DataProductCard';
import PricingPlanCard from '../../library/PricingPlanCard';
import {
  OdpsValidationResult, Offer, syncOfferWithLocalStorage, validateOdpsWithExceptions,
} from '../../../Helpers';
import { DataProductForm } from '../../../productForms';
import WizardStep from '../../library/form/WizardStep';
import Loader from '../../Loader';

function Review() {
  const params = useParams();
  const navigate = useNavigate();
  const { data, loading: getLoading } = useGetMyProductQuery({
    fetchPolicy: 'network-only',
    context: {
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    variables: {
      id: params.productId ?? '',
    },
  });

  const [submitDataProduct, { loading: submitLoading }] = useSubmitDataProductMutation({});

  const [odpsValidationResult, setOdpsValidationResult] = useState<OdpsValidationResult | undefined>(undefined);
  useEffect(() => {
    setOdpsValidationResult(undefined);
    if (data && data.dataMarket.userProduct.openDataProductSpecification) {
      try {
        const odps = validateOdpsWithExceptions(data.dataMarket.userProduct.openDataProductSpecification);
        setOdpsValidationResult({ errorMessage: undefined, success: true, validOdps: odps });
      } catch (exception: any) {
        setOdpsValidationResult({ errorMessage: exception?.toString(), success: false, validOdps: undefined });
      }
    }
  }, [data]);

  useEffect(() => {
    if (data?.dataMarket?.userProduct && params.productId) {
      syncOfferWithLocalStorage(params.productId, data?.dataMarket.userProduct.id);
    }
  }, [data]);

  function onSubmit() {
    if (data?.dataMarket.userProduct.id) {
      submitDataProduct({
        variables: {
          id: data?.dataMarket.userProduct.id,
        },
        context: {
          headers: {
            [WeCityOrganizationIdHeader]: params.orgId,
          },
        },
      }).then((_) => {
        const offers = (JSON.parse(localStorage.getItem(OfferingsLocalStorageKey) ?? '[]') ?? []) as Offer[];
        localStorage.setItem(OfferingsLocalStorageKey, JSON.stringify(offers.filter((o) => o.id !== data?.dataMarket.userProduct.id)));
        navigate(OfferWizardCongratulationsRoute(params.orgId, params.productId));
      });
    }
  }

  return (
    <WizardStep form={DataProductForm} currentStep={8} onSubmit={() => onSubmit()}>
      {!getLoading
        ? (
          <>
            <div className="bg-gray-200 w-full p-2">
              {odpsValidationResult && odpsValidationResult.validOdps ? (
                <div className="space-y-6">
                  <DataProductCard odps={odpsValidationResult.validOdps} />
                  <div
                    className="columns-1 md:columns-2 lg:columns-3 gap-12 space-y-6 lg:w-full break-inside-avoid-column"
                  >
                    {odpsValidationResult.validOdps?.product?.pricingPlans?.en.map((pricingPlan: any) => (
                      <div key={`pricplan-${crypto.randomUUID()}`} className="flex lg:block justify-center">
                        <PricingPlanCard
                          pricingPlan={pricingPlan}
                          productName={odpsValidationResult.validOdps?.product?.en?.name ?? 'Plan'}
                          productId="00000000-0000-0000-0000-000000000000"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ) : odpsValidationResult?.errorMessage}
            </div>

            {submitLoading ? (
              <Button
                type="button"
                disabled
                className="flex opacity:50 items-center w-full justify-self-center self-start rounded-lg text-center font-medium"
              >
                <div className="px-0.5 py-0.5">Submit my product</div>
              </Button>
            ) : (
              <Button
                type="button"
                onClick={() => onSubmit()}
                className="flex items-center w-full justify-self-center self-start rounded-lg text-center font-medium"
              >
                <div className="px-0.5 py-0.5">Submit my product</div>
              </Button>
            )}
          </>
        ) : <Loader />}
    </WizardStep>
  );
}

export default Review;
